import { useContext, useEffect, useRef } from 'react';
import { usePathname } from 'next/navigation';
import Image from 'next/image';
import Link from 'next/link';

import { Button, parseMarkdown } from '@humanoids/ui';

import { HeroBlockType } from '@humanoids/services/graphql/sdk';
import { generatePath } from '@/services';
import { HeroTextSwap } from '../hero-text-swap/HeroTextSwap';
import { StoreContext } from '../store-provider';
import PlusDoodleSvg from '@/images/plus-doodle.svg';

import * as Styled from './HeroBlock.styled';

export const HeroBlock = ({
  align,
  alternate,
  callToAction,
  callToActionPage,
  callToActionUrl,
  heading,
  image,
  intro,
  outro,
  swapWords,
  text,
  video,
}: HeroBlockType) => {
  const [{ configuration }] = useContext(StoreContext);
  const videoRef = useRef(null);
  const pathname = usePathname();

  const path = callToActionPage
    ? `/${generatePath(callToActionPage, configuration).join('/')}`
    : `${pathname}${callToActionUrl}` ?? '#';

  const replaceCharacters = (text: string) => {
    return text.replace('+', `<img alt="+" src="${PlusDoodleSvg}" />`);
  };

  const scrollHandler = () => {
    const { bottom, top } = videoRef.current.getBoundingClientRect();

    if (
      (top >= 0 && top < window.innerHeight) ||
      (bottom >= 68 && bottom <= window.innerHeight)
    ) {
      // Catch is needed to prevent false positive in Sentry
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      videoRef.current.play().catch(() => {});
    } else {
      videoRef.current.pause();
    }
  };

  useEffect(() => {
    if (!videoRef.current) {
      return;
    }

    scrollHandler();

    window.addEventListener('scroll', scrollHandler, { passive: true });

    return () => window.removeEventListener('scroll', scrollHandler);
  }, []);

  return (
    <Styled.HeroBlock>
      <Styled.Background alternate={alternate}>
        <Styled.Container>
          {swapWords && (
            <HeroTextSwap intro={intro} outro={outro} swapWords={swapWords} />
          )}
          {!swapWords && heading && (
            <Styled.Heading
              align={align ?? 'center'}
              alternate={alternate}
              dangerouslySetInnerHTML={{
                __html: replaceCharacters(parseMarkdown(heading)),
              }}
            />
          )}
          {text && (
            <Styled.Text
              $align="center"
              alternate={alternate}
              dangerouslySetInnerHTML={{
                __html: parseMarkdown(text),
              }}
              $extraLarge
            />
          )}
          {callToAction && (
            <Link href={path} passHref>
              <Button secondary>{callToAction}</Button>
            </Link>
          )}
          {image?.asset && (
            <Styled.Image>
              <Image
                alt={image.alt}
                blurDataURL={image.asset.metadata.lqip}
                fill
                placeholder="blur"
                priority
                quality={85}
                sizes={Styled.imageMediaCondition}
                src={image.asset.url}
              />
            </Styled.Image>
          )}
          {video?.asset && (
            <Styled.Video>
              <video loop muted playsInline ref={videoRef}>
                {/* Added #t=0.1 to source to show first frame of the video on page load */}
                <source src={`${video.asset.url}#t=0.1`} type="video/mp4" />
              </video>
            </Styled.Video>
          )}
          <Styled.LineDoodleImage>
            <Image alt="line doodle" fill src="/images/line-doodle.svg" />
          </Styled.LineDoodleImage>
          {swapWords ? (
            <Styled.StarsImage>
              <Image alt="stars" fill priority src="/images/stars.svg" />
            </Styled.StarsImage>
          ) : (
            <Styled.DotsTriangleImage>
              <Image
                alt="dots triangle"
                fill
                priority
                src="/images/dots-triangle.svg"
              />
            </Styled.DotsTriangleImage>
          )}
        </Styled.Container>
      </Styled.Background>
    </Styled.HeroBlock>
  );
};
